import Container from '../components/Container'
import Head from 'next/head'
import { useEffect, useRef, useState } from 'react'
import { useActiveWeb3React } from '../hooks/useActiveWeb3React'
import { useCurrencyBalance } from '../state/wallet/hooks'
import Web3Connect from '../components/Web3Connect'
import { useTransactionAdder } from '../state/transactions/hooks'
import useMarket, { useListings, useOwned, useAdmin } from '../features/coco/useMarketplace'
import usePurchase from '../features/coco/usePurchase'
import { BigNumber } from '@ethersproject/bignumber'
import { GEM_ADDRESS, MARKET_ADDRESS } from '../config/addresses'
import { getAddress } from '@ethersproject/address'
import { Token } from '@sushiswap/sdk'
import { tryParseAmount } from '../functions'
import { ApprovalState, useApproveCallback } from '../hooks'
import Dots from '../components/Dots'
import { CSVLink } from 'react-csv'
import { useBDracoBalance, useDracoBalance } from '../features/coco/useCocoDraco'
import { request } from 'graphql-request'

export default function Dashboard() {
  const [type, setType] = useState(1)
  const [count, setCount] = useState([])
  const { account, chainId } = useActiveWeb3React()
  const myRefname= useRef(null);
  
  const { purchase, addListing, editListing, removeListing } = useMarket()

  const addTransaction = useTransactionAdder()

  const listings = useListings()
  const owned = useOwned()
  const admin = useAdmin()
  const [data, setData] = useState([])
  const dracoBalance = useDracoBalance()
  const bDracoBalance = useBDracoBalance()

  const liquidityToken = GEM_ADDRESS[chainId]
    ? new Token(chainId, getAddress(GEM_ADDRESS[chainId]), 18, 'DRA')
    : undefined

  const parsedDepositValue = tryParseAmount('1', liquidityToken)
  const [approvalState, approve] = useApproveCallback(parsedDepositValue, MARKET_ADDRESS[chainId])

  const balance = useCurrencyBalance(account, liquidityToken)

  const [limit, setLimit] = useState({})
  const [discordId, setDiscordId] = useState(localStorage.getItem('discordId'))
  const [listingArr, setListingArr] = useState([])
  const [listingName, setListingName] = useState('')
  const [listingImage, setListingImage] = useState('')
  const [listingDescription, setListingDescription] = useState('')
  const [listingQty, setListingQty] = useState('')
  const [listingExpiry, setListingExpiry] = useState('')
  const [listingPrice, setListingPrice] = useState('')
  const [listingMax, setListingMax] = useState('')
  const [listingDiscord, setListingDiscord] = useState('')
  const [listingTwitter, setListingTwitter] = useState('')
  const [exports, setExports] = useState([])
  const [fileName, setFileName] = useState('')

  useEffect(() => {
    if (listings.length > 0) {
      if(listingArr.length < 1){
        setCount(Array(listings.length).fill(1))
        let list = []
        for (var i = 0; i < listings.length; i++) {
          list.push({
            deleted: listings[i].deleted,
            expiry: listings[i].expiry,
            image: listings[i].image,
            maxCount: listings[i].maxCount,
            name: listings[i].nameAndDesc.split("|")[0],
            description: listings[i].nameAndDesc.split("|")[1],
            price: listings[i].price,
            slots: listings[i].slots,
            discord: listings[i].socials.split("|")[0],
            twitter: listings[i].socials.split("|")[1]
          })
        }
        setListingArr(list)
      }
    }
  }, [listings])

  useEffect(() => {
    if (owned.length > 0) {
      let list = {}
      for (var i = 0; i < owned.length; i++) {
        list[owned[i].listingIndex] = list[owned[i].listingIndex]
          ? list[owned[i].listingIndex] + owned[i].count
          : owned[i].count
      }
      setLimit(list)
    }
  }, [owned])

  const crypto = require('crypto')
  const DiscordOauth2 = require('discord-oauth2')
  const oauth = new DiscordOauth2({
    clientId: '952836557675565106',
    redirectUri: 'https://app.dracoversenft.com/',
  })
  let search = window.location.search
  let params = new URLSearchParams(search)
  let code = params.get('code')
  let epoch = Date.now() / 1000
  
  useEffect(() => {
    async function load(code) {
      if (code && !discordId) {
        try {
          const req = await fetch(`/api/discord`, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code: code }),
          })
          const resp = await req.json()
          localStorage.setItem('discordId', resp.discordId)
          setDiscordId(resp.discordId)
        } catch (e) {
          console.log(e)
        }
      }
    }
    load(code)
  }, [code])

  return (
    <Container id="dashboard-page" className="py-4 md:py-8 lg:py-12" style={{ maxWidth: '100%' }}>
      <Head>
        <title>Marketplace | Dracoverse</title>
        <meta name="description" content="Whitelist Marketplace" />
      </Head>
      {/*
      <div style={{ textAlign: "center", opacity: 1 }} className="custom-box custom-container mb-4">
        Public sale starts on 30 Nov 5PM GMT
      </div>
      */}
      <div className="custom-font">Whitelist Marketplace</div>
      <div className="tab-container">
        <div className={`tab ${type === 1 && 'active'}`} onClick={() => setType(1)}>
          Live Listings
        </div>
        <div className={`tab ${type === 2 && 'active'}`} onClick={() => setType(2)}>
          All Listings
        </div>
        <div className={`tab ${type === 3 && 'active'}`} onClick={() => setType(3)}>
          My Purchase
        </div>
        {admin && (
          <div className={`tab ${type === 4 && 'active'}`} onClick={() => setType(4)}>
            Admin
          </div>
        )}
      </div>
      <div className="grid grid-cols-12 gap-4">
        {(type === 1 || type === 2) && discordId && (
          <div className="custom-font col-span-12" style={{ fontSize: '32px', marginBottom: '-20px' }}>
            {`Discord ID : ${discordId}`}&nbsp;
            <span
              style={{ color: 'red', cursor: 'pointer' }}
              onClick={() => {
                localStorage.setItem('discordId', '')
                setDiscordId('')
              }}
            >
              X
            </span>
          </div>
        )}
        {/* (type === 1 || type === 2) && (
          <div className="custom-font col-span-12" style={{ fontSize: '32px', marginBottom: '-20px', marginTop: "10px" }}>
            <div
              style={{ fontSize:"24px", cursor: 'pointer', display: "flex", alignItems:"center" }}
              onClick={() => {
                setLiveOnly(!liveOnly)
              }}
            >
              Live Only <div style={{ marginLeft: "12px", border: "2px solid black", height: "18px", width: "18px", display: "flex", alignItems: "center" }}>{liveOnly && <>&#10004;</>}</div>
            </div>
          </div>
        ) */}
        {(type === 1 || type === 2) &&
          listings &&
          listings.slice(0).reverse().map(
            (listing, i) =>
              !listing.deleted && (type === 2 || listing.expiry > epoch) && (
                <div
                  key={listings.length - 1 - i}
                  className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 box-bg listings"
                  style={{ padding: '20px 15px', position: "relative" }}
                >
                  {listing.expiry <= epoch && <div style={{ position: "absolute", background: "rgba(0, 0, 0, 0.6)", width: "100%", height: "100%", left: "0", top: "0", borderRadius: "16px" }}></div>}
                  <div className="title">{listing.nameAndDesc.split("|")[0]}</div>
                  <img
                    alt="LISTING IMAGE"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null // prevents looping
                      currentTarget.src = '/logo.png'
                    }}
                    src={listing.image}
                  />
                  <div className="title" style={{ fontSize:"14px", marginBottom: "0", minHeight: "64px"}}>{listing.nameAndDesc.split("|")[1]}</div>
                  <div className="flex custom-container">
                    <div>Quantity</div>
                    <div style={{ display: 'flex' }}>
                      <img
                        className="arrow"
                        onClick={() => {
                          if(count[listings.length - 1 - i] > 1) {
                            count[listings.length - 1 - i] -= 1;
                            setCount([...count])
                          }
                        }}
                        src="/down.svg"
                        alt="down"
                        style={{ marginTop: '5px', height: '20px', marginRight: '8px' }}
                      />
                      {count[listings.length - 1 - i]}
                      <img
                        className="arrow"
                        onClick={() => { 
                          if (count[listings.length - 1 - i] < listing.maxCount && count[listings.length - 1 - i] < listing.slots) {
                            count[listings.length - 1 - i] += 1;
                            setCount([...count])
                          }
                        }}
                        src="/up.svg"
                        alt="up"
                        style={{ marginTop: '6px', height: '20px', marginLeft: '8px' }}
                      />
                    </div>
                  </div>
                  <div style={{ height: '2px', background: '#9D8AAD' }} />

                  <div className="flex custom-container">
                    <div>Total Price</div>
                    <div>{(Number(listing.price) * count[listings.length - 1 - i]) / 1e18} DRA</div>
                  </div>
                  {!account ? (
                    <Web3Connect size="lg" className="w-full mint-btn" />
                  ) : !discordId || discordId === '' ? (
                    <div
                      className="mint-btn"
                      onClick={() => {
                        const url = oauth.generateAuthUrl({
                          scope: ['identify'],
                          state: crypto.randomBytes(16).toString('hex'), // Be aware that randomBytes is sync if no callback is provided
                        })
                        window.location.replace(url)
                      }}
                    >
                      LOGIN DISCORD
                    </div>
                  ) : listing.expiry <= epoch ? (
                    <div className="mint-btn" style={{ opacity: '0.5' }}>
                      EXPIRED
                    </div>
                  ) : limit[listings.length - 1 - i] && limit[listings.length - 1 - i] + count[listings.length - 1 - i] > listing.maxCount ? (
                    <div className="mint-btn" style={{ opacity: '0.5' }}>
                      MAX LIMIT
                    </div>
                  ) : listing.slots < 1 ? (
                    <div className="mint-btn" style={{ opacity: '0.5' }}>
                      SOLD OUT
                    </div>
                  ) : parseFloat(((Number(listing.price) * count[listings.length - 1 - i]) / 1e18).toFixed(2)) >
                    parseFloat(balance.toFixed(2)) ? (
                    <div className="mint-btn" style={{ opacity: '0.5' }}>
                      NOT ENOUGH DRA
                    </div>
                  ) : dracoBalance.lt(1) && bDracoBalance.lt(1) ? (
                    <div className="mint-btn" style={{ opacity: '0.5' }}>
                      MUST HOLD 1 DRACO/BDRACO
                    </div>
                  ) : approvalState === ApprovalState.NOT_APPROVED || approvalState === ApprovalState.PENDING ? (
                    <div
                      className="mint-btn"
                      onClick={() => {
                        if (approvalState === ApprovalState.NOT_APPROVED) approve()
                      }}
                      style={{ opacity: approvalState === ApprovalState.PENDING && '0.5' }}
                    >
                      {approvalState === ApprovalState.PENDING ? <Dots>APPROVING</Dots> : `APPROVE`}
                    </div>
                  ) : (
                    <div
                      className="mint-btn"
                      style={{ opacity: count[listings.length - 1 - i] === 0 && '0.5' }}
                      onClick={async () => {
                        if (count[listings.length - 1 - i] > 0) {
                          try {
                            // index, discordId, count, value
                            let tx = await purchase(listings.length - 1 - i, discordId, count[listings.length - 1 - i])
                            addTransaction(tx, {
                              summary: `Purchased ${count[listings.length - 1 - i]} ${listing.name} WL`,
                            })
                            setCount(Array(listings.length).fill(1))
                          } catch (e) {}
                        }
                      }}
                    >
                      PURCHASE
                    </div>
                  )}
                  <div>
                    <div className="flex custom-container">
                      <div>Amount Left</div>
                      <div>{listing.expiry <= epoch ? 'Expired' : listing.slots.toNumber()}</div>
                    </div>
                  </div>
                  <div>
                    <div className="flex" style={{ marginTop: "10px", justifyContent: "center" }}>
                      <a target="_blank" rel="noreferrer" href={listing.socials && listing.socials.split("|")[0]}><img style={{ marginBottom: "0", height: "24px", width: "24px", marginRight: "16px" }} src="/discord.svg" /></a>
                      <a target="_blank" rel="noreferrer" href={listing.socials && listing.socials.split("|")[1]}><img style={{ marginBottom: "0", height: "24px", width: "24px", marginLeft: "16px" }} src="/twitter.svg" /></a>
                    </div>
                  </div>
                </div>
              )
          )}
        {type === 3 &&
          listings &&
          owned &&
          owned.slice(0).reverse().map((own, i) => (
            <div
              key={i}
              className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 box-bg listings"
              style={{ padding: '20px 15px', minHeight: "480px" }}
            >
              <div className="title">{listings[own.listingIndex].name}</div>
              <img
                alt="LISTING IMAGE"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = '/logo.png'
                }}
                src={listings[own.listingIndex].image}
              />

              <div className="flex custom-container">
                <div>Discord</div>
                <div style={{ display: 'flex' }}>{own.discordId}</div>
              </div>
              <div className="flex custom-container" style={{ paddingTop: '0' }}>
                <div>Quantity</div>
                <div style={{ display: 'flex' }}>{own.count.toNumber()}</div>
              </div>
              <div style={{ height: '2px', background: '#9D8AAD' }} />
              <div className="flex custom-container">
                <div>Total Price</div>
                <div>{Number(own.total) / 1e18} DRA</div>
              </div>
            </div>
          ))}
        {type === 4 && (
          <>
            <div
              className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 box-bg admin"
              style={{ padding: '20px 15px', minHeight: '1000px' }}
            >
              <div className="title">NEW LISTING</div>
              <img
                alt="LISTING IMAGE"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null // prevents looping
                  currentTarget.src = '/logo.png'
                }}
                src={listingImage}
              />
              <div className="flex custom-container custom-box">
                <input placeholder="Name" value={listingName} onChange={(e) => setListingName(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Image Url" value={listingImage} onChange={(e) => setListingImage(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Description" value={listingDescription} onChange={(e) => setListingDescription(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Quantity" value={listingQty} onChange={(e) => setListingQty(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Expiry" value={listingExpiry} onChange={(e) => setListingExpiry(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input
                  placeholder="Price ( DRA )"
                  value={listingPrice}
                  onChange={(e) => setListingPrice(e.target.value)}
                />
              </div>
              <div className="flex custom-container custom-box">
                <input
                  placeholder="Max Per Address"
                  value={listingMax}
                  onChange={(e) => setListingMax(e.target.value)}
                />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Discord" value={listingDiscord} onChange={(e) => setListingDiscord(e.target.value)} />
              </div>
              <div className="flex custom-container custom-box">
                <input placeholder="Twitter" value={listingTwitter} onChange={(e) => setListingTwitter(e.target.value)} />
              </div>
              <div
                className="mint-btn"
                onClick={async () => {
                  try {
                    let tx = await addListing(
                      listingName + "|" + listingDescription,
                      listingImage,
                      listingQty,
                      listingExpiry,
                      listingPrice,
                      listingMax,
                      listingDiscord + "|" + listingTwitter
                    )
                    setListingName('')
                    setListingImage('')
                    setListingDescription('')
                    setListingQty('')
                    setListingExpiry('')
                    setListingPrice('')
                    setListingMax('')
                    setListingDiscord('')
                    setListingTwitter('')
                    addTransaction(tx, {
                      summary: `Added ${listingName}`,
                    })
                  } catch (e) {}
                }}
              >
                ADD
              </div>
            </div>
            {listingArr &&
              listingArr.slice(0).reverse().map(
                (listing, i) =>
                  !listing.deleted && (
                    <div
                      key={i}
                      className="lg:col-span-3 md:col-span-4 sm:col-span-6 col-span-12 box-bg admin"
                      style={{ padding: '20px 15px' }}
                    >
                      <CSVLink
                        style={{ display: "none" }}
                        filename={fileName}
                        data={data}
                        ref={myRefname}
                      />
                      <div
                        className="export-btn"
                        onClick={() => {
                          // purchases.purchaseEntities
                          request('https://api.thegraph.com/subgraphs/name/popicorny/dracoverse-mainnet', `
                            {
                              purchaseEntities(where: { listingId: ${listingArr.length - 1 - i} } ) {
                                id 
                                from
                                discordId
                                count
                                total
                                timestamp
                              }
                            }
                          `).then((response) => {
                            setFileName(listing.name)
                            setData(response.purchaseEntities);
                            myRefname.current.link.click();
                          });
                        }}
                      >
                        CSV
                      </div>
                      <div className="title">EDIT LISTING</div>
                      <div
                        className="delete-btn"
                        onClick={async () => {
                          try {
                            let tx = await removeListing(listingArr.length - 1 - i)
                            addTransaction(tx, {
                              summary: `Removed ${listing.nameAndDesc.split("|")[0]}`,
                            })
                          } catch (e) {}
                        }}
                      >
                        X
                      </div>
                      <img
                        alt="LISTING IMAGE"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null // prevents looping
                          currentTarget.src = '/logo.png'
                        }}
                        src={listing.image}
                      />
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Name"
                          value={listing.name}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].name = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Image Url"
                          value={listing.image}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].image = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Description"
                          value={listing.description}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].description = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Quantity"
                          value={listing.slots}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].slots = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Expiry"
                          value={listing.expiry}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].expiry = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Price ( DRA )"
                          value={listing.price / 1e18}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].price = BigNumber.from(e.target.value).mul(BigNumber.from('10').pow(18))
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Max Per Address"
                          value={listing.maxCount}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].maxCount = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Discord"
                          value={listing.discord}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].discord = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div className="flex custom-container custom-box">
                        <input
                          placeholder="Twitter"
                          value={listing.twitter}
                          onChange={(e) => {
                            listingArr[listingArr.length - 1 - i].twitter = e.target.value
                            setListingArr([...listingArr])
                          }}
                        />
                      </div>
                      <div
                        className="mint-btn"
                        onClick={async () => {
                          try {
                            let tx = await editListing(
                              listingArr.length - 1 - i,
                              listingArr[listingArr.length - 1 - i].name  + "|" + listingArr[listingArr.length - 1 - i].description,
                              listingArr[listingArr.length - 1 - i].image,
                              listingArr[listingArr.length - 1 - i].slots,
                              listingArr[listingArr.length - 1 - i].expiry,
                              listingArr[listingArr.length - 1 - i].price,
                              listingArr[listingArr.length - 1 - i].maxCount,
                              listingArr[listingArr.length - 1 - i].discord  + "|" + listingArr[listingArr.length - 1 - i].twitter,
                            )
                            addTransaction(tx, {
                              summary: `Edited ${listingArr[listingArr.length - 1 - i].name}`,
                            })
                          } catch (e) {}
                        }}
                      >
                        EDIT
                      </div>
                    </div>
                  )
              )}
          </>
        )}
      </div>
    </Container>
  )
}
