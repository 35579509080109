import { useActiveWeb3React, useSushiContract } from '../../hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { useCallback, useMemo } from 'react'
import { useMarketContract } from '../../hooks/useContract'
import { MARKET_ADDRESS } from '../../config/addresses'
import { NEVER_RELOAD, useSingleCallResult } from '../../state/multicall/hooks'
import { JSBI } from '@sushiswap/sdk'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'

export default function useMarketplace() {
  const { chainId } = useActiveWeb3React()
  const contract = useMarketContract(MARKET_ADDRESS[chainId])

  const purchase = useCallback(
    async (index, discordId, count) => {
      try {
        // uint256 _index, string calldata _discordId, uint256 _count
        let tx = await contract?.purchase(index, discordId, count)
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const addListing = useCallback(
    async (name, image, slots, expiry, price, maxCount, socials) => {
      try {
        // uint256 _index, string calldata _discordId, uint256 _count
        let tx = await contract?.addListing(
          name,
          image,
          slots,
          expiry,
          BigNumber.from(price).mul(BigNumber.from('10').pow(18)),
          maxCount,
          socials
        )
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const editListing = useCallback(
    async (index, name, image, slots, expiry, price, maxCount, socials) => {
      try {
        // uint256 _index, string calldata _discordId, uint256 _count
        let tx = await contract?.editListing(index, name, image, slots, expiry, BigNumber.from(price), maxCount, socials)
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const removeListing = useCallback(
    async (index) => {
      try {
        // uint256 _index, string calldata _discordId, uint256 _count
        let tx = await contract?.removeListing(index)
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  return { purchase, addListing, editListing, removeListing }
}

export function useAdmin() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useMarketContract(MARKET_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const only = useSingleCallResult(contract, 'admins', inputs)?.result
  if (only?.[0] === undefined) return false
  return only?.[0]
}

export function useListings() {
  const { chainId } = useActiveWeb3React()
  const contract = useMarketContract(MARKET_ADDRESS[chainId])

  const only = useSingleCallResult(contract, 'getListings', undefined)?.result
  if (only?.[0] === undefined) return []
  return only?.[0]
}

export function useOwned() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useMarketContract(MARKET_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const result = useSingleCallResult(contract, 'getOwned', inputs)?.result
  if (result?.[0] === undefined) return []
  return result?.[0]
}
