import { useActiveWeb3React } from '../../hooks'
import { BigNumber } from '@ethersproject/bignumber'
import { Zero } from '@ethersproject/constants'
import { useCallback, useMemo } from 'react'
import { useBabyContract, useCCDContract } from '../../hooks/useContract'
import { BABY_ADDRESS, CCD_ADDRESS, GEM_ADDRESS, STAKING_ADDRESS } from '../../config/addresses'
import { NEVER_RELOAD, useSingleCallResult } from '../../state/multicall/hooks'
import { JSBI } from '@sushiswap/sdk'
import useCurrentBlockTimestamp from '../../hooks/useCurrentBlockTimestamp'

export default function useCocoDraco() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])
  const babyContract = useBabyContract(BABY_ADDRESS[chainId])

  const babyMint = useCallback(
    async (amount: BigNumber) => {
      try {
        let tx = await babyContract?.mint(amount, { gasLimit: 220000 })
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [babyContract]
  )

  const freeMint = useCallback(async () => {
    try {
      let tx = await contract?.freeMint()
      return tx
    } catch (e) {
      console.error(e)
      return e
    }
  }, [contract])

  const presaleMint = useCallback(
    async (amount: BigNumber, value: BigNumber, proof) => {
      try {
        let tx = await contract?.presaleMint(amount, proof, { value: value, gasLimit: 220000 })
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const mint = useCallback(
    async (amount: BigNumber, value: BigNumber) => {
      console.log(amount.toString())
      console.log(value.toString())
      try {
        let tx = await contract?.mint(amount, { value: value, gasLimit: 250000 })
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const claim = useCallback(
    async (tokenIds) => {
      try {
        let tx = await contract?.claim(tokenIds)
        return tx
      } catch (e) {
        console.error(e)
        return e
      }
    },
    [contract]
  )

  const approveAll = useCallback(async () => {
    try {
      let tx = await babyContract?.setApprovalForAll(STAKING_ADDRESS[chainId], true)
      return tx
    } catch (e) {
      console.error(e)
      return e
    }
  }, [contract])

  return { mint, claim, presaleMint, freeMint, approveAll, babyMint }
}

export function useOnlyWhiteListed() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const only = useSingleCallResult(contract, 'presaleActive', undefined)?.result
  if (only?.[0] === undefined) return true
  return only?.[0]
}

export function useFreeAddress() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const result = useSingleCallResult(contract, 'freeWhitelist', inputs)?.result
  const value = result !== undefined ? BigNumber.from(result?.[0]) : 0
  return value
}

export function useWhiteListedAddress(proof) {
  const { account, chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const inputs = useMemo(() => [proof, account ?? undefined], [account, proof])

  const result = useSingleCallResult(contract, 'whitelistCount', inputs)?.result
  const value = result !== undefined ? BigNumber.from(result?.[0]) : 0
  return value
}

export function usePublicRemain() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const result = useSingleCallResult(contract, 'totalSupply', undefined)?.result
  const value = result !== undefined ? parseFloat(result?.[0]) : 0

  return value
}

export function useTotalSupply() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const result = useSingleCallResult(contract, 'maxDraco', undefined)?.result
  const value = result !== undefined ? parseFloat(result?.[0]) : 0

  return value
}

export function useBabyRemain() {
  const { chainId } = useActiveWeb3React()
  const contract = useBabyContract(BABY_ADDRESS[chainId])

  const result = useSingleCallResult(contract, 'totalSupply', undefined)?.result
  const value = result !== undefined ? parseFloat(result?.[0]) : 0

  return value
}

export function useRareTracker() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const result = useSingleCallResult(contract, 'rareTracker', undefined)?.result
  const value = result !== undefined ? parseFloat(result?.[0]) : 0

  return value
}

export function useStarted() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const only = useSingleCallResult(contract, 'saleActive', undefined)?.result
  if (only?.[0] === undefined) return false
  return only?.[0]
}

export function usePrice() {
  const { chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const price = useSingleCallResult(contract, 'price', [])?.result
  return price?.[0] !== undefined ? BigNumber.from(price?.[0].toString()) : 0
}

export function useRarePrice() {
  const { chainId } = useActiveWeb3React()
  const contract = useBabyContract(BABY_ADDRESS[chainId])

  const price = useSingleCallResult(contract, 'price', [])?.result
  return price?.[0] !== undefined ? BigNumber.from(price?.[0].toString()) : 0
}

export function useDracoBalance() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const result = useSingleCallResult(contract, 'balanceOf', inputs)?.result
  return result?.[0] !== undefined ? BigNumber.from(result?.[0].toString()) :  BigNumber.from(0)
}

export function useWalletOfOwner() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useCCDContract(CCD_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const result = useSingleCallResult(contract, 'walletOfOwner', inputs)?.result
  return result !== undefined ? result : [[], BigNumber.from(0)]
}

export function useBDracoBalance() {
  const { account, chainId } = useActiveWeb3React()
  const contract = useBabyContract(BABY_ADDRESS[chainId])

  const inputs = useMemo(() => [account ?? undefined], [account])

  const result = useSingleCallResult(contract, 'balanceOf', inputs)?.result
  return result?.[0] !== undefined ? BigNumber.from(result?.[0].toString()) :  BigNumber.from(0)
}

export function useBase() {
  const { chainId } = useActiveWeb3React()
  const contract = useBabyContract(BABY_ADDRESS[chainId])

  const base = useSingleCallResult(contract, 'baseURI', undefined)?.result
  return base !== undefined ? base?.[0] : undefined
}
